var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showRScoresChart
      ? _c(
          "div",
          [
            _c("apexchart", {
              staticClass: "apex-charts",
              attrs: {
                type: "bar",
                dir: "ltr",
                height: "362",
                series: _vm.trafficLightQualityChartData,
                options: _vm.trafficLightQualityChartOptions,
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center mt-3" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }