<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/shared/components/page-header";
import Dashboard from "../components/widgets/dashboard.vue";
import AuthService from "@/api/services/auth.service.js";

/**
 * Dashboard
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    Dashboard
    // CompanyDashboard
  },
  data() {
    return {
      items: [
        {
          text: this.$t("dashboard.pageTitle"),
          href: "/"
        }
      ],
      /***** Data variables *****/
      loggedInUser: null
      /****** Operational variable ******/
    };
  },
  async mounted() {
    await this.getLoginUser();
  },
  methods: {
    async getLoginUser() {
      let getLoggedInUserFromFunction = await AuthService.getLoggedInUser();
      this.loggedInUser = getLoggedInUserFromFunction;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="this.$t('dashboard.pageTitle')" :items="items" />

    <!-- View Row -->
    <div class="row mt-4">
      <div class="col-12">
        <Dashboard />
      </div>
    </div>
  </Layout>
</template>
