var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isPageLoading
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-4" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row animate__animated animate__fadeInUp animate__slow",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [_c("ProfileCard")],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-xl-8" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row animate__animated animate__fadeInUp animate__slow",
                },
                _vm._l(_vm.statData, function (stat) {
                  return _c(
                    "div",
                    { key: stat.icon, staticClass: "col-md-4" },
                    [
                      _c("Stat", {
                        attrs: {
                          icon: stat.icon,
                          translationKey: stat.translationKey,
                          value: stat.value,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            this.statData[0].value > 0
              ? _c("div", { staticClass: "col-xl-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-none d-md-block animate__animated animate__fadeInUp animate__slow",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("h4", { staticClass: "card-title mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("dashboard.chartCard.title")) +
                                " "
                            ),
                          ]),
                          _c("DashboardCharts"),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 animate__animated animate__fadeInUp animate__slow",
                  },
                  [
                    _c("div", { staticClass: "card text-center" }, [
                      _c(
                        "div",
                        { staticClass: "card-body p-5" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("dashboard.noDatafiles.title")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("dashboard.noDatafiles.text")) +
                              " "
                          ),
                          _c("br"),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickRedirectToUpload()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "bx bx-upload font-size-18 mr-2",
                              }),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("dashboard.noDatafiles.startNowButton")
                                ) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
          ]),
        ])
      : _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-6 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }