<script>
import QualityScoreService from "@/api/services/qualitySocreInfo.service.js";
import { numberOfQualityScoresChartOptions } from "@/shared/components/chart-options";

export default {
  data() {
    return {
      numberOfQualityScoresChartOptions: null,
      scoreQualityChartData: [],
      showQualityScoreChart: false
    };
  },
  async mounted() {
    this.numberOfQualityScoresChartOptions = numberOfQualityScoresChartOptions;
    await this.setNumberOfQualityScoresData();
  },
  methods: {
    async setNumberOfQualityScoresData() {
      let PSOccurrences = [];
      let CSOccurrences = [];
      let TSOccurrences = [];
      let IBSOccurrences = [];
      let OESOccurrences = [];
      // fetch datafiles that the user has uploaded
      const response =
        await QualityScoreService.getDashboardNumberOfQualityScores();
      for (const datafileScoreTypes of response.scoreTypes) {
        PSOccurrences.push(Math.round(datafileScoreTypes.PS * 100) / 100);
        CSOccurrences.push(Math.round(datafileScoreTypes.CS * 100) / 100);
        TSOccurrences.push(Math.round(datafileScoreTypes.TS * 100) / 100);
        IBSOccurrences.push(Math.round(datafileScoreTypes.IBS * 100) / 100);
        OESOccurrences.push(Math.round(datafileScoreTypes.OES * 100) / 100);
      }
      let xAxisLabels = response.datafileNames;
      this.numberOfQualityScoresChartOptions.xaxis.categories = [
        ...xAxisLabels
      ];
      let PSObject = {
        name: "PS",
        data: PSOccurrences
      };
      let CSObject = {
        name: "CS",
        data: CSOccurrences
      };
      let TSObject = {
        name: "TS",
        data: TSOccurrences
      };
      let IBSObject = {
        name: "GQS",
        data: IBSOccurrences
      };
      let OESObject = {
        name: "OES",
        data: OESOccurrences
      };
      this.scoreQualityChartData.push(PSObject);
      this.scoreQualityChartData.push(CSObject);
      this.scoreQualityChartData.push(TSObject);
      this.scoreQualityChartData.push(IBSObject);
      this.scoreQualityChartData.push(OESObject);

      // this.$refs.scoreQualitySummaryChart.updateOptions(
      //   this.numberOfQualityScoresChartOptions
      // );

      this.showQualityScoreChart = true;
    }
  }
};
</script>
<template>
  <div>
    <div v-if="showQualityScoreChart">
      <apexchart
        class="apex-charts"
        ref="scoreQualitySummaryChart"
        type="bar"
        dir="ltr"
        height="362"
        :series="scoreQualityChartData"
        :options="numberOfQualityScoresChartOptions"
      ></apexchart>
    </div>

    <div class="row" v-else>
      <div class="col-md-12 text-center mt-3">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
