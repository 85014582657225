var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c(
          "b-tabs",
          { attrs: { "content-class": "p-3 text-muted" } },
          [
            _c(
              "b-tab",
              {
                staticClass: "border-0",
                attrs: { active: "", lazy: "" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          { staticClass: "d-inline-block d-sm-none" },
                          [_c("i", { staticClass: "bx bxs-bar-chart-square" })]
                        ),
                        _c(
                          "span",
                          { staticClass: "d-none d-sm-inline-block" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("dashboard.chartCard.rScoreTab.title")
                              ) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [_c("div", [_c("traffic-light-quality-chart")], 1)]
            ),
            _c(
              "b-tab",
              {
                attrs: { lazy: "" },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          { staticClass: "d-inline-block d-sm-none" },
                          [_c("i", { staticClass: "bx bxs-bar-chart-alt-2" })]
                        ),
                        _c(
                          "span",
                          { staticClass: "d-none d-sm-inline-block" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dashboard.chartCard.qualityScoreTab.title"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [_c("div", [_c("number-of-quality-scores-chart")], 1)]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }