var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: this.$t("dashboard.pageTitle"), items: _vm.items },
      }),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-12" }, [_c("Dashboard")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }