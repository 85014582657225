<script>
/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
  props: {
    translationKey: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  },
  computed: {
    getTranslationByKey() {
      return this.$t(this.translationKey);
    },
    computedTitle() {
      if (this.title == "totalDatafiles") {
        return this.$t("dashboard.totalDatafiles");
      } else if (this.title == "totalDatasets") {
        return this.$t("dashboard.totalDatasets");
      }
      return this.$t("dashboard.totalQualityScores");
    }
  }
};
</script>

<template>
  <div class="card mini-stats-wid">
    <div class="card-body">
      <div class="media">
        <div class="media-body">
          <p class="text-muted font-weight-medium">{{ getTranslationByKey }}</p>
          <h4 class="mb-0">{{ value.toLocaleString("de-DE") }}</h4>
        </div>

        <div class="mini-stat-icon avatar-sm align-self-center rounded-circle">
          <span :class="`avatar-title ${color}`">
            <i :class="`${icon} font-size-24`"></i>
          </span>
        </div>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
