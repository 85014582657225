<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import NumberOfQualityScoresChart from "@/shared/components/widgets/number-of-quality-scores-chart.vue";
import TrafficLightQualityChart from "@/redem/dashboards/components/traffic-light-quality-chart.vue";
export default {
  components: { TrafficLightQualityChart, NumberOfQualityScoresChart }
};
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <b-tabs content-class="p-3 text-muted">
        <!---------- Traffic Light Quality Chart ------->
        <b-tab active class="border-0" lazy>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              <i class="bx bxs-bar-chart-square"></i>
            </span>
            <span class="d-none d-sm-inline-block"
              >{{ $t("dashboard.chartCard.rScoreTab.title") }}
            </span>
          </template>
          <div>
            <traffic-light-quality-chart />
          </div>
        </b-tab>
        <!------------ Number of Quality Scores Chart ------------>
        <b-tab lazy>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              <i class="bx bxs-bar-chart-alt-2"></i>
            </span>
            <span class="d-none d-sm-inline-block">
              {{ $t("dashboard.chartCard.qualityScoreTab.title") }}
            </span>
          </template>
          <div>
            <number-of-quality-scores-chart />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
