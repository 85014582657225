import { render, staticRenderFns } from "./dashboard-charts.vue?vue&type=template&id=68e140e6&"
import script from "./dashboard-charts.vue?vue&type=script&lang=js&"
export * from "./dashboard-charts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\mitte\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('68e140e6')) {
      api.createRecord('68e140e6', component.options)
    } else {
      api.reload('68e140e6', component.options)
    }
    module.hot.accept("./dashboard-charts.vue?vue&type=template&id=68e140e6&", function () {
      api.rerender('68e140e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/dashboards/components/widgets/dashboard-charts.vue"
export default component.exports