var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mini-stats-wid" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "media" }, [
        _c("div", { staticClass: "media-body" }, [
          _c("p", { staticClass: "text-muted font-weight-medium" }, [
            _vm._v(_vm._s(_vm.getTranslationByKey)),
          ]),
          _c("h4", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.value.toLocaleString("de-DE"))),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "mini-stat-icon avatar-sm align-self-center rounded-circle",
          },
          [
            _c("span", { class: `avatar-title ${_vm.color}` }, [
              _c("i", { class: `${_vm.icon} font-size-24` }),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }