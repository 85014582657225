var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card overflow-hidden" }, [
    _c("div", { staticClass: "bg-white", staticStyle: { height: "100px" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-7" }, [
          _c(
            "div",
            { staticClass: "text-primary p-3" },
            [
              _c("h5", { staticClass: "text-primary" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("dashboard.welcomeCard.welcome")) + " "
                ),
              ]),
              _c("h5", { staticClass: "font-size-15" }, [
                _vm._v(
                  " " +
                    _vm._s(this.loggedInUser.name.first) +
                    " " +
                    _vm._s(this.loggedInUser.name.last) +
                    " "
                ),
              ]),
              _c("b-badge", { attrs: { variant: "warning" } }, [
                this.loggedInUser.accessLevel ===
                _vm.accessLevelEnum.SUPER_ADMIN
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dashboard.welcomeCard.accessLevel.superAdmin"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                this.loggedInUser.accessLevel === _vm.accessLevelEnum.ADMIN
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("dashboard.welcomeCard.accessLevel.admin")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                this.loggedInUser.accessLevel === _vm.accessLevelEnum.EMPLOYEE
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("dashboard.welcomeCard.accessLevel.employee")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5 text-right" }, [
      _c("img", {
        staticClass: "mt-1",
        staticStyle: { width: "150px" },
        attrs: { src: require("@/assets/images/Spring.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }